.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100vw;
  background: white;
  font-family: 'Quicksand', sans-serif;
  position: relative;
  margin-top: 200px;
}

.contact-content {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  gap: 50px;
}

.contact-section {
  display: flex;
  width: 100%;
}

.contact-header {
  width: 40%;
  font-size: 2rem;
  font-weight: bold;
  text-align: left;
}

.contact-text {
  font-size: 1.2rem;
  text-align: left;
  line-height: 1.8;
}

@media (max-width: 768px) {
  .contact-container{
    margin-top: 100px;
  }

  .contact-content {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    
  }

  .contact-section {
    display: flex;
    flex-direction: column; 
    align-items: center;
    text-align: center;
    width: 100%;
  }

  .contact-header {
    width: 100%;
    font-size: 1.8rem;
    text-align: center;
  }

  .contact-text {
    width: 90%;
    font-size: 1rem;
    text-align: center;
    line-height: 1.6;
  }

  .contact-quote {
    font-style: italic;
    font-size: 1.5rem;
    font-weight: bold;
    margin: 20px auto;
    margin-top: 20px;
    max-width: 90%;
    color: #333;
  }
}
