.onboarding-form { 
  max-width: 600px; 
  margin: 90px auto; 
  margin-top: 70px; 
  padding: 25px; 
  padding-top: 0px; 
  background: transparent; 
  border: 1px solid #eee; 
  border-radius: 16px; 
  font-family: "Brunch", sans-serif; 
  font-size: 0.9rem; 
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.05); 
} 

.onboarding-form h2 { 
  text-align: center; 
  font-size: 1.4rem; 
  color: #222; 
  margin-bottom: 30px; 
} 

.onboarding-form > label { 
  display: block; 
  font-weight: 600; 
  margin: 30px 0 16px; 
  font-size: 0.95rem; 
} 

.onboarding-form input[type="text"], 
.onboarding-form input[type="email"], 
.onboarding-form textarea { 
  width: 100%; 
  padding: 12px; 
  font-size: 0.9rem; 
  border-radius: 10px; 
  border: 1px solid #ddd; 
  box-sizing: border-box; 
  transition: border-color 0.2s, box-shadow 0.2s;
  background-color: #f9f9f9;
} 

.onboarding-form input[type="text"]:focus,
.onboarding-form input[type="email"]:focus,
.onboarding-form textarea:focus {
  outline: none;
  border-color: #6366F1;
  box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.1);
  background-color: #fff;
}

.checkbox-group { 
  display: flex; 
  flex-direction: column; 
  gap: 10px; 
  margin-top: 5px;
} 

.checkbox-group label { 
  display: flex; 
  align-items: center; 
  gap: 8px; 
  font-weight: 400; 
  font-size: 0.9rem; 
  cursor: pointer; 
  padding: 6px 10px;
  border-radius: 6px;
  transition: background-color 0.2s;
} 

.checkbox-group label:hover {
  background-color: #f5f5f5;
}

.checkbox-group input[type="checkbox"] {
  width: 18px;
  height: 18px;
  border-radius: 4px;
  accent-color: #6366F1;
}

.onboarding-form button[type="submit"] { 
  margin-top: 30px; 
  width: 100%; 
  padding: 14px; 
  background: Black; 
  color: #fff; 
  border: none; 
  font-weight: bold; 
  border-radius: 10px; 
  cursor: pointer; 
  font-size: 0.95rem; 
  transition: all 0.3s ease; 
  box-shadow: 0 4px 12px rgba(99, 102, 241, 0.2);
} 

.onboarding-form button[type="submit"]:hover { 
  background: black;
  box-shadow: 0 6px 16px rgba(99, 102, 241, 0.3);
  transform: translateY(-1px);
} 

.onboarding-form button[type="submit"]:active {
  transform: translateY(1px);
  box-shadow: 0 2px 8px rgba(99, 102, 241, 0.2);
}

.success { 
  text-align: center; 
  font-size: 1.2rem; 
  color: green; 
  margin-top: 100px; 
} 

.error { 
  color: rgb(69, 15, 15); 
  font-size: 0.9rem; 
  margin-top: 10px; 
} 

.onboarding-form.chat-mode { 
  max-width: 100%; 
  margin: 0; 
  padding: 10px 0; 
  font-size: 0.9rem; 
  border: none; 
  box-shadow: none; 
} 

.onboarding-form.chat-mode h2 { 
  font-size: 1.2rem; 
  margin-bottom: 20px; 
  text-align: center;
  background: black;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 700;
} 

.onboarding-form.chat-mode > label { 
  margin: 18px 0 8px; 
  font-size: 0.9rem;
  color: #444;
} 

.onboarding-form.chat-mode input, 
.onboarding-form.chat-mode textarea { 
  font-size: 0.9rem; 
  padding: 10px 12px; 
  border-radius: 8px;
} 

.onboarding-form.chat-mode button[type="submit"] { 
  padding: 12px; 
  font-size: 0.9rem;
  margin-top: 24px;
} 

/* Responsive mobile overrides */ 
@media (max-width: 480px) { 
  .onboarding-form { 
    padding: 20px; 
    margin: 50px 16px; 
    font-size: 0.85rem; 
  } 
  .onboarding-form h2 { 
    font-size: 1.2rem; 
  } 
  .onboarding-form > label { 
    margin: 20px 0 10px; 
    font-size: 0.85rem; 
  } 
  .onboarding-form input, 
  .onboarding-form textarea { 
    padding: 10px; 
    font-size: 0.85rem; 
  } 
  .onboarding-form button[type="submit"] { 
    padding: 12px; 
    font-size: 0.9rem; 
  } 
}
