.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100vw;
  background: white;
  font-family: 'Quicksand', sans-serif;
  position: relative;
  margin-top: 200px;
}
.about-quote {
  width: 70%;
  text-align: left;
  line-height: 1.8;
  font-style: italic;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

.about-content {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  gap: 50px;
}

.about-section {
  display: flex;
  width: 100%;
}

.about-header {
  width: 30%;
  font-size: 2rem;
  font-weight: bold;
  text-align: left;
}

.about-text {
  width: 70%;
  font-size: 1.2rem;
  text-align: left;
  line-height: 1.8;
}

@media (max-width: 768px) {
  .about-container{
    margin-top: 100px;
    padding-bottom: 100px;

  }

  .about-content {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    
  }

  .about-section {
    display: flex;
    flex-direction: column; 
    align-items: center;
    text-align: center;
    width: 100%;
  }

  .about-header {
    width: 100%;
    font-size: 1.8rem; 
    text-align: center;
    margin-bottom: 15px;
  }

  .about-text {
    width: 90%;
    font-size: 1rem;
    text-align: center;
    line-height: 1.6;
  }

  .about-quote {
    width: 90%;
    font-size: 1rem;
    text-align: center;
    line-height: 1.6;
  }

}
