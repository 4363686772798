.home {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.video-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.centered-text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.centered-text {
  max-width: 80%;
  height: auto;
}

.chat-trigger {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 999;
  transition: all 0.3s ease;
  overflow: hidden;
}

.chat-trigger:hover {
  background-color: #333;
  transform: translateY(-3px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.25);
}

.chat-trigger:active {
  transform: translateY(0);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.chat-trigger svg {
  width: 24px;
  height: 24px;
  stroke: white;
  stroke-width: 2;
  fill: none;
  transition: transform 0.3s ease;
}

.chat-trigger:hover svg {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .centered-text {
    max-width: 90%;
  }

  .chat-trigger {
    width: 50px;
    height: 50px;
    bottom: 15px;
    right: 15px;
  }

  .chat-trigger svg {
    width: 22px;
    height: 22px;
  }
}

