@font-face {
  font-family: 'Brunches';
  src: url('../../public/fonts/Brunches-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Brunches';
  src: url('../../public/fonts/Brunches-Rounded.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand';
  src: url('../../public/fonts/quicksand.bold-regular.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand';
  src: url('../../public/fonts/quicksand.boldoblique-regular.otf') format('opentype');
  font-weight: bold;
  font-style: oblique;
}

@font-face {
  font-family: 'Quicksand';
  src: url('../../public/fonts/quicksand.book-regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand';
  src: url('../../public/fonts/quicksand.light-regular.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand';
  src: url('../../public/fonts/quicksand.lightoblique-regular.otf') format('opentype');
  font-weight: 300;
  font-style: oblique;
}

@font-face {
  font-family: 'MalloryEpping';
  src: url('../../public/fonts/Malloryepping-Regular.otf') format('truetype');
  font-display: swap;
}

body {
    font-family: 'Quicksand', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #fff;
    color: #333;
  }
  
  * {
    text-transform: uppercase;
  }
  

  .handwritten {
    font-family: 'MalloryEpping', sans-serif;
    white-space: pre-wrap;
    height: 100px;
    font-size: 2rem;
    padding-left: 100px;
    padding-right: 70px;
    color: gray;
  }

  @media screen and (max-width: 600px) {
    .handwritten {
      font-size: 1.3rem;
      padding:0 0px 0px 20px;
      height: 50px;
    }
  }