.chat-popup-container { 
  position: fixed; 
  bottom: 80px; 
  right: 20px; 
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
} 

.chat-popup { 
  background: #fff; 
  width: 350px; 
  max-height: 75vh; 
  border-radius: 16px; 
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(0, 0, 0, 0.05); 
  overflow-y: auto; 
  padding: 20px 24px 24px; 
  position: relative; 
  animation: popupFadeIn 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  scrollbar-width: thin; 
  -webkit-overflow-scrolling: touch;
  transform-origin: bottom right;
} 

.chat-close-button { 
  position: absolute; 
  top: 16px; 
  right: 16px; 
  background: rgba(240, 240, 240, 0.8); 
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; 
  color: #555;
  transition: all 0.2s ease;
  padding: 0;
  z-index: 10;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
} 

.chat-close-button:hover {
  background: rgba(220, 220, 220, 1);
  color: #333;
  transform: scale(1.05);
}

.chat-close-button:active {
  transform: scale(0.95);
}

.chat-popup::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 6px;
  background: white;
  border-radius: 16px 16px 0 0;
}

.chat-popup-arrow {
  position: absolute;
  bottom: -10px;
  right: 24px;
  width: 20px;
  height: 10px;
  overflow: hidden;
}

.chat-popup-arrow::after {
  content: '';
  position: absolute;
  width: 14px;
  height: 14px;
  background: white;
  transform: translateY(-10px) rotate(45deg);
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.15);
}

@keyframes popupFadeIn { 
  from { 
    opacity: 0; 
    transform: translateY(20px) scale(0.95); 
  } 
  to { 
    opacity: 1; 
    transform: translateY(0) scale(1); 
  } 
} 

@keyframes popupFadeOut {
  from {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
  to {
    opacity: 0;
    transform: translateY(20px) scale(0.95);
  }
}

.chat-popup.closing {
  animation: popupFadeOut 0.3s cubic-bezier(0.16, 1, 0.3, 1) forwards;
}

@media (max-width: 480px) { 
  .chat-popup {
    width: 100%;
    max-width: 320px; /* Decreased from 400px */
    max-height: 60vh;  /* Decreased height */
    padding: 12px 16px 16px; /* Slightly reduced padding */
    border-radius: 12px;
    font-size: 13px; /* Smaller base font size for all text */
  }
  
  .chat-close-button {
    width: 28px;
    height: 28px;
    top: 12px;
    right: 12px;
  }
  
  .chat-popup-arrow {
    right: 50%;
    transform: translateX(50%);
  }
  
  .onboarding-form.chat-mode {
    font-size: 13px;
  }
  
  .onboarding-form.chat-mode h2 {
    font-size: 15px;
    margin-bottom: 12px;
  }
  
  .onboarding-form.chat-mode > label {
    font-size: 13px;
    margin: 12px 0 4px;
  }
  
  .onboarding-form.chat-mode input,
  .onboarding-form.chat-mode textarea {
    font-size: 13px;
    padding: 8px;
    border-radius: 6px;
  }
  
  .onboarding-form.chat-mode button[type="submit"] {
    font-size: 13px;
    padding: 10px;
    margin-top: 16px;
  }
  
}
