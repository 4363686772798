.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-box {
    background: white;
    padding: 30px;
    width: 90%;
    max-width: 500px;
    border-radius: 12px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.25);
    font-family: "Quicksand", sans-serif;
  }
  
  .modal-box h2 {
    margin-bottom: 20px;
  }
  
  .modal-box input,
  .modal-box textarea {
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 6px;
  }
  
  .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 20px;
  }
  
  .modal-actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 50px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'Quicksand', sans-serif;
  }
  
  .modal-actions button:first-child {
    background-color: transparent;
    color: #333;
    border: 1px solid #ccc;
  }
  
  .modal-actions button:first-child:hover {
    background-color: #f2f2f2;
  }
  
  .modal-actions button:last-child {
    background-color: black;
    color: white;
  }
  
  .modal-actions button:last-child:hover {
    background-color: #222;
  }
  
  .button-group {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 1000;
  }
  
  .submit-button,
  .submit-button-alt {
    padding: 12px 20px;
    background-color: black;
    color: white;
    border: none;
    font-weight: 600;
    font-size: 14px;
    border-radius: 50px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .submit-button:hover,
  .submit-button-alt:hover {
    background-color: #222;
  }
  