.services-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100vw;
  background: white;
  font-family: "Quicksand", sans-serif;
  position: relative;
  margin-top: 200px;
  min-height: 100vh; /* Ensure it expands as needed */
  padding-bottom: 120px;  

}

.services-content {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  gap: 50px;
}

.services-section {
  display: flex;
  width: 100%;
}

.services-header {
  width: 35%;
  font-size: 2rem;
  font-weight: bold;
  text-align: left;
}

.services-text {
  width: 70%;
  font-size: 1.2rem;
  text-align: left;
  line-height: 1.8;
}

@media (max-width: 768px) {
  .services-container {
    margin-top: 100px;
    min-height: 100vh;
    padding-bottom: 100px; 

  }

  .services-content {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .services-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
  }

  .services-header {
    width: 100%;
    font-size: 1.8rem;
    text-align: center;
  }

  .services-text {
    width: 90%;
    font-size: 1rem;
    text-align: center;
    line-height: 1.6;
  }
}
