.custom-form-container {
  background: transparent;
  font-family: 'Quicksand', sans-serif;
  position: relative;
}

@media (max-width: 768px) {
  .custom-form-container {
    padding: 20px;
  }
  
}